import React, { useState } from "react";
import {
  Stack,
  TextField,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Radio,
  FormLabel,
  Checkbox,
} from "@mui/material";
import Coechipier1 from "./Coechipier1";
import Coechipier2 from "./Coechipier2";

const Date = ({sectiuneSelectata}) => {

  const [participaEchipa, setParticipaEchipa] = useState(false);
  const [numarMembriEchipa, setNumarMembriEchipa] = useState(0);

  const handleSelectParticipaEchipa = (event) => {
    const optiune = event.target.name;
    setParticipaEchipa(optiune === "Q9_1" || optiune === "Q9_2");

    if (optiune === "Q9_1") {
      setNumarMembriEchipa(2);
    } else if (optiune === "Q9_2") {
      setNumarMembriEchipa(3);
    } else {
      setNumarMembriEchipa(0);
    }
  };

  return (
    <Stack
      sx={{
        gap: "32px",
        ">*": {
          gap: "32px",
          //   "::after": {
          //     content: '" "',
          //     width: "100%",
          //     border: "1px solid #CCCC",
          //   },
          //   ":last-child::after": {
          //     content: "none",
          //     width: "0%",
          //   },
        },
      }}
    >
      <Stack>
        <TextField
          required
          name="Nume"
          label="1. Nume și prenume:"
          fullWidth
          variant="standard"
        />
        <TextField
          required
          name="Număr telefon"
          label="2. Telefon"
          fullWidth
          variant="standard"
        />
        <TextField
          required
          name="Email"
          type="email"
          id="uniq_email"
          label="3. Email"
          fullWidth
          variant="standard"
        />
        <TextField
          required
          name="Facebook"
          label="4. Facebook (optional)"
          fullWidth
          variant="standard"
        />
        <TextField
          required
          name="Localitate"
          label="5. Localitate:"
          fullWidth
          variant="standard"
        />
        <TextField
          required
          name="Facultate/Liceu"
          label="6. Facultate/Liceu:"
          fullWidth
          variant="standard"
        />
        <FormControl component="fieldset">
          <FormLabel component="legend">7. Ai nevoie de cazare:</FormLabel>
          <RadioGroup row aria-label="cazare">
            <FormControlLabel
              name="Cazare"
              value="26 Aprilie"
              control={<Radio />}
              label="26 Aprilie"
            />
            <FormControlLabel
              name="Cazare"
              value="27 Aprilie"
              control={<Radio />}
              label="27 Aprilie"
            />
            <FormControlLabel
              name="Cazare"
              value="Ambele zile"
              control={<Radio />}
              label="Ambele zile"
            />
            <FormControlLabel
              name="Cazare"
              value="Nu am nevoie de cazare"
              control={<Radio />}
              label="Nu am nevoie de cazare"
            />
          </RadioGroup>
        </FormControl>
    
        {sectiuneSelectata !== 'Junior Dev' && (
        <FormControl component="fieldset">
          <FormLabel component="legend">8. Particip ca echipa?</FormLabel>
          <RadioGroup
            row
            aria-label="cazare"
            name="cazare1"
            onChange={handleSelectParticipaEchipa}
          >
            <FormControlLabel name = "Q9_0" value= "NU"
              control={<Radio />} label="NU" />
            <FormControlLabel
              name="Q9_1"
              value="DA, 2 membri"
              control={<Radio />}
              label="DA, 2 membri"
            />
            <FormControlLabel
              name = "Q9_2"
              value="DA, 3 membri"
              control={<Radio />}
              label="DA, 3 membri"
            />
          </RadioGroup>
        </FormControl>
      )}
      </Stack>
      {participaEchipa && numarMembriEchipa === 2 && <Coechipier1 />}
      {participaEchipa && numarMembriEchipa === 3 && (
        <>
          <Coechipier1 />
          <Coechipier2 />
        </>
      )}
      <FormControlLabel
        control={<Checkbox required name="GDPR" id="GDPR" />}
        label="23. Ești de acord ca LSAC să prelucreze datele tale personale mai sus menționate în concordanță cu regulamentele UE GDPR în vigoare?"
      />
    </Stack>
  );
};

export default Date;
