import React, { useState } from "react";
import { Grid, Stack, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { TextField, MenuItem } from "@mui/material";
import Date from "./components/Date";
import DotsTexture from "./../../../src/shared/images/dots-texture.png";
import { Link } from "react-router-dom";
import Socials from "../../components/Socials";

import emailjs from "@emailjs/browser";

const sendEmail = (e) => {
  let parms = 
  {
    name: 'caca',
    email: document.getElementById('uniq_email').value,
    subject: 'Inregistrare IT Marathon',
    message: 'Inregistrare IT Marathon',
  }
  emailjs.send('service_nhrwhua', 'template_mfaq7ir', parms, 'pR-QpVvTJEdVeJeCR');
}

const Sectiuni = [
  {
    value: "Design",
    label: "Design este o proba foarte faina.",
  },
  {
    value: "Capture the Flag",
    label: "Capture the Flag",
  },
  {
    value: "CPU Modeling and Design",
    label: "CPU Modeling and Design",
  },
  {
    value: "Web Development",
    label: "Web Development",
  },
  {
    value: "Desktop",
    label: "Desktop",
  },
  {
    value: "Junior Dev",
    label: "Junior Dev",
  },
];

const Inscrieri = () => {

  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const response = await fetch("https://script.google.com/macros/s/AKfycbzvd1SiLBxW2AYm_KlgX-_ck9f7LBaujPw8cyXPnkUwX5jRb0ncqzrshp1N_L4PsPj6aQ/exec"
    , {
      method: 'POST',
      body: new FormData(event.target),
    });

    const result = await response.json();
    console.log(result.result);
    if (result.result === 'success') {
      setIsSubmitted(true);
      alert('Inregistrat cu succes! Verifică-ți email-ul pentru a confirma înscrierea!');
    }
    else
    {
      alert('Nu am putut trimite datele tale! Contacteaza un administrator!');
    }
  };

  const onFormSubmit = (event) =>
  {
    sendEmail(event);
    handleSubmit(event);
  }

  




  const [sectiuneSelectata, setSectiuneSelectata] = useState("");
  const [afisareDate, setAfisareDate] = useState(false);

  const handleSelectSectiune = (event) => {
    setSectiuneSelectata(event.target.value);
    setAfisareDate(true);
  };

  return (
    <Grid
      container
      sx={{
        width: "100vw",
        height: "100vh",
        background: `url(${DotsTexture}), linear-gradient(#303841 0%, #303841 100%)`,
        alignItems: "center",
        justifyContent: "center",
        padding: "32px 0",
        overflowX: "hidden",
        backgroundSize: "cover",
      }}
    >
      <Grid
        item
        container
        xs={10}
        md={6}
        sx={{
          height: "calc(100% - 72px)",
          ">*": {
            gap: "16px",
            display: "flex",
            flexDirection: "column",
            padding: "32px",
            background: "#16191BAA",
            borderRadius: "10px",
            overflow: "auto",
            height: "calc(100% - 72px)",
            "&::-webkit-scrollbar": {
              width: {
                xs: "0px",
                md: "26px",
              },
              borderRadius: "13px",
              backgroundClip: "padding-box",
              border: "10px solid transparent",
              height: "15%",
            },
            "&::-webkit-scrollbar-thumb": {
              width: {
                xs: "0px",
                md: "26px",
              },
              borderRadius: "13px",
              backgroundClip: "padding-box",
              border: "10px solid transparent",
              boxShadow: "inset 0 0 0 10px",
              height: "15%",
            },
          },
        }}
      >
        <form
          method="POST"
          id="form"
          name="google-sheet"
          onSubmit={onFormSubmit}
          gap="16px"
        >
          <Stack gap="8px">
            <Typography
              display="flex"
              variant="h4"
              fontWeight="800"
              color="#F3F3F3"
              flexDirection="row"
            >
              Inscrieri IT Marathon <Stack color="#2185D5">&nbsp;2024</Stack>
            </Typography>
            <Typography
              variant="body3"
              fontWeight="500"
              color="#CCCCCC"
              sx={{
                fontFamily: "Montserrat",
                textAlign: { xs: "left", sm: "left", md: "left" },
                width: "100%",
                ">*": {
                  color: "#2185D5",
                  textDecoration: "none",
                  fontWeight: "600",
                },
              }}
            >
              "IT Marathon" este concurs de nivel național adresat studenților
              și elevilor de liceu pasionați de IT, crescând în fiecare an ca
              număr de participanți și de secțiuni, a ajuns în 2024 la a XII-a
              sa ediție. Probele au loc pe 27 Aprilie, iar
              Târgul "InfoIT" pentru job-uri are loc pe data de 28 aprilie,
              între orele 10 și 16. Urmăriţi calendarul competiției pe{" "}
              <Link onClick={() => window.history.back()} target="_blank" rel="noreferrer">
                site
              </Link>
              .
            </Typography>
          </Stack>
          <TextField
            id="filled-select-currency"
            select
            label="Select"
            name="Proba"
            defaultValue=""
            helperText="Sectiunea*"
            variant="filled"
            onChange={handleSelectSectiune}
          >
            {Sectiuni.map((option) => (
              <MenuItem name="Proba" key={option.value} value={option.value}>
                {option.value}
              </MenuItem>
            ))}
          </TextField>
          {afisareDate && <Date sectiuneSelectata={sectiuneSelectata}/>}
          <Button type="submit" variant="contained" color="primary" disabled={isSubmitted}>
        Trimite
      </Button>
        </form>
      </Grid>
      <Grid
        item
        container
        sx={{
          alignItems: "center",
          justifyContent: "center",
          //padding: "16px",
          flexDirection: "column",
        }}
      >
        <Typography
          variant="h6"
          fontWeight="800"
          color="#F3F3F3"
          flexDirection="row"
          sx={{
            display: "flex",
          }}
        >
          Follow&nbsp;
          <Typography variant="h6" fontWeight="800" color="#2185D5">
            ITM
          </Typography>{" "}
          &nbsp;there
        </Typography>
        <Socials />
      </Grid>
    </Grid>
  );
};

export default Inscrieri;
