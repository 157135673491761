import React from "react";
import { Stack, Typography } from "@mui/material";
import { LsacIcon } from "../shared/icons";
import Socials from "./Socials";

const Footer = () => (
  <Stack
    sx={{
      background: "#16191B",
      padding: "32px",
      flexDirection: "column",
      gap: "32px",
    }}
  >
    <Stack
      sx={{
        flexDirection: "row",
        alignItems: "flex-start",
        justifyContent: "space-between",
        height: "max-content",
        gap: "16px",
        flexWrap: "wrap",
        ">*": {
          flexGrow: "1",
          width: {
            xs: "calc(50% - 16px)",
            md: "calc(33.3% - 16px)",
          },
        },
      }}
    >
      <Stack
        direction="column"
        gap="8px"
        sx={{
          alignItems: "flex-start",
          //background: "red",
        }}
      >
        <Typography
          sx={{
            color: "#16191B",
            fontWeight: "500",
            lineHeight: "normal",
            fontFamily: "Montserrat",
            background: "#CCC",
            padding: "2px",
            width: "max-content",
          }}
        >
          Powered by
        </Typography>
        <LsacIcon sx={{ fill: "#F3F3F3", width: "auto", height: "32px" }} />
      </Stack>
      <Stack
        gap="8px"
        sx={{
          alignItems: "center",
          //background: "red",
          justifyContent: "center",
        }}
      >
        <Typography
          sx={{
            color: "#16191B",
            fontWeight: "500",
            lineHeight: "normal",
            fontFamily: "Montserrat",
            background: "#CCC",
            padding: "2px",
          }}
        >
          Also find us on
        </Typography>
        <Socials />
      </Stack>
      <Stack
        gap="8px"
        sx={{
          alignItems: {
            xs: "flex-start",
            md: "flex-end",
          },
          //background: "red",
        }}
      >
        <Typography
          sx={{
            color: "#16191B",
            fontWeight: "500",
            lineHeight: "normal",
            fontFamily: "Montserrat",
            background: "#CCC",
            padding: "2px",
            width: "max-content",
          }}
        >
          Contact Us
        </Typography>
        <Stack
          sx={{
            flexDirection: "row",
          }}
        >
          <Typography
            sx={{
              color: "#F3F3F3",
              fontWeight: "700",
              lineHeight: "normal",
              fontFamily: "Montserrat",
            }}
          >
            e-mail:
          </Typography>
          <Typography
            sx={{
              color: "#CCC",
              fontWeight: "500",
              lineHeight: "normal",
              fontFamily: "Montserrat",
            }}
          >
            &nbsp;lsaciasi@gmail.com
          </Typography>
        </Stack>
        <Stack
          sx={{
            flexDirection: "row",
          }}
        >
          <Typography
            sx={{
              color: "#F3F3F3",
              fontWeight: "700",
              lineHeight: "normal",
              fontFamily: "Montserrat",
            }}
          >
          </Typography>
        </Stack>
      </Stack>
    </Stack>
    <Typography
      sx={{
        color: "#CCC",
        fontWeight: "500",
        lineHeight: "normal",
        fontFamily: "Montserrat",
        textAlign: "center",
        fontStyle: "italic",
        fontSize: "0.875rem",
      }}
    >
      Copyright &copy; 2024. All rights reserved.
    </Typography>
  </Stack>
);

export default Footer;
