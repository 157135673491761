import React from "react";
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import Inscrieri from "./pages/Inscrieri/Inscrieri";
import Home from "./Home";
import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from "@mui/material/styles";
import FaQ from "./pages/FaQ/FaQ";

function App() {
  let theme = createTheme({
    typography: {
      fontFamily: "Montserrat, sans-serif",
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        mdPlus: 1060,
        lg: 1200,
        xl: 1536,
      },
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            "--TextField-brandBorderColor": "#F3F3F3",
            "--TextField-brandBorderHoverColor": "#757575",
            "--TextField-brandBorderFocusedColor": "#2185D5",
            "& label.Mui-focused": {
              color: "#CCC",
            },
            color: "red",
          },
        },
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            color: "#F3F3F3",
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            borderColor: "#CCC",
          },
          root: {
            color: "white",
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          label: {
            color: "#cccc", // Setează culoarea textului
            fontWeight: "400",
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            color: "#2185D5",
            fontWeight: "600",
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            color: "#CCC",
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: "#F3F3F3",
            "&.Mui-checked": {
              color: "#2185D5",
            },
          },
        },
      },
      MuiFilledInput: {
        styleOverrides: {
          root: {
            "&::before, &::after": {
              borderBottom: "2px solid var(--TextField-brandBorderColor)",
            },
            "&:hover:not(.Mui-disabled, .Mui-error):before": {
              borderBottom: "2px solid var(--TextField-brandBorderHoverColor)",
            },
            "&.Mui-focused:after": {
              borderBottom:
                "2px solid var(--TextField-brandBorderFocusedColor)",
            },
            color: "#CCC",
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          root: {
            "&::before": {
              borderBottom: "2px solid var(--TextField-brandBorderColor)",
            },
            "&:hover:not(.Mui-disabled, .Mui-error):before": {
              borderBottom: "2px solid var(--TextField-brandBorderHoverColor)",
            },
            "&.Mui-focused:after": {
              borderBottom:
                "2px solid var(--TextField-brandBorderFocusedColor)",
            },
            color: "#F3F3F3",
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          icon: {
            fill: "#2185D5",
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          paper: {
            background: "#303841",
            color: "#F3F3F3",
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: "#CCC", // Change this to your desired color
            "&.Mui-focused": {
              color: "#F3", // Change this to your desired color
            },
          },
          standard: {
            color: "#F3f3f3",
            fontWeight: "400",
          },
        },
      },
      //   MuiInputBase: {
      //     styleOverrides: {
      //       input: {
      //         color: "#red", // Change this to your desired color
      //         fontWeight: "700",
      //         "&::placeholder": {
      //           color: "#F3F3F3", // Change this to your desired color
      //         },
      //       },
      //     },
      //   },
    },
  });
  theme = responsiveFontSizes(theme);

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/inscrieri" element={<Inscrieri />} />
          <Route path="/faq" element={<FaQ />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
