import React, { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { Button, Stack, Dialog, Grid, Typography } from "@mui/material";
import ImaginiProbe from "./ImagineProbe";
import { CaptureTheFlagIcon } from "../../../shared/icons";

let currentIndex = 0;

const PozeDesignImport = require.context(
  "../../../shared/images/probe/Design",
  true
);
const PozeDesign = PozeDesignImport.keys().map((img) => PozeDesignImport(img));

const PozeDesktopImport = require.context(
  "../../../shared/images/probe/Desktop",
  true
);
const PozeDesktop = PozeDesktopImport.keys().map((img) =>
  PozeDesktopImport(img)
);

const PozeJuniorImport = require.context(
  "../../../shared/images/probe/Junior",
  true
);
const PozeJunior = PozeJuniorImport.keys().map((img) => PozeJuniorImport(img));

const Pozex86Import = require.context("../../../shared/images/probe/Cpu", true);
const Pozex86 = Pozex86Import.keys().map((img) => Pozex86Import(img));

const PozeWebImport = require.context("../../../shared/images/probe/Web", true);
const PozeWeb = PozeWebImport.keys().map((img) => PozeWebImport(img));

const Probe = () => {
  const dialogTexts = [
    "Junior Dev",
    "Mobile & Desktop",
    "Design",
    "Web Development",
    "CPU Design & Modeling",
    "Capture The Flag",
  ];
  const buttonTexts = [
    "Junior Dev",
    "Mobile & Desktop",
    "Design",
    "Web Development",
    "CPU Design & Modeling",
    "Capture The Flag",
  ];
  const texts = [
    "Această secţiune are ca scop introducerea elevilor de liceu în atmosfera competiţiilor universitare. Concursul propriu-zis constă în rezolvarea unei serii de probleme într-un interval de 8 ore, probleme de algoritmică, de dificultăţi variate, ce se bazează pe materia de liceu, dar care necesită o viziune mai amplă asupra domeniului. Problemele sunt de tipul celor prezente la olimpiada de informatică. Câştigătorul este desemnat în funcţie de eficienţa soluţiilor furnizate, de timpul şi memoria necesare rulării acestora. Acesta este beneficiarul unui loc la Facultatea de Automatică şi Calculatoare, fără a mai fi necesară susţinerea testului de admitere.",
    "În cadrul acestei secţiuni participanţii vor realiza o aplicaţie, pornind de la tema dată, în cadrul căreia îşi vor pune în valoare cunoştinţele şi imaginaţia pentru a oferi o soluţie cât mai cuprinzătoare. În ediţiile precedente temele au variat de la editoare de text, până la algoritmi de inteligenţă artificială implementaţi sub forma unui joc, având libertate totală în alegerea tehnologiei folosite. La una dintre ediţii, concurenţii au avut ca temă dezvoltarea unui joc interactiv care să implementeze un algoritm de inteligenţă artificială şi o interfaţă grafică cât mai atractivă. În evaluarea aplicaţiilor rezultante s-au urmărit complexitatea jocului şi a algoritmilor utilizaţi, uşurinţa utilizării interfeţei grafice sau posibilitatea alegerii nivelului de dificultate.",
    "Această probă constă în utilizarea unor programe precum Adobe Photoshop, Adobe Illustrator etc. pentru a realiza diferite materiale promoţionale(afişe, flyere, stickere etc.) pe teme propuse de un juriu competent. Câştigătorul este desemnat în funcţie de creativitatea participanţilor, complexitatea elementelor adăugate de aceştia şi aspectul final.",
    "În cadrul acestei categorii concurenţii trebuie să dezvolte o aplicaţie web în conformitate cu cerinţele furnizate. Temele propuse sunt de actualitate pe piaţa IT şi rezolvă probleme reale cu care se confruntă dezvoltatorii. Tehnologiile folosite sunt alese liber de către programatori, singura necesitate fiind o cunoaştere profundă a acestora. Într-o ediţie concursul a necesitat elaborarea unei aplicaţii prin care utilizatorii puteau oferi cadouri sub formă de vouchere sau tichete reprezentate de un număr de serie. Unele echipe au reuşit să integreze în aplicaţiile lor API-uri ce transformau codurile în coduri QR ce puteau fi scanate apoi cu telefonul mobil de către cei ce primeau cadoul, introducând astfel o notă de interactivitate.",
    "Această probă este perfectă pentru pasionații de hard, scopul ei fiind de a implementa un procesor în C++. Plecând de la cunoștințe din zona arhitecturii calculatoarelor până la conceptele OOP, proba va fi creată special pentru cei care vor să experimenteze și alte domenii",
    "Soluțiile premiate vor fi alese în funcție de eficiența implementărilor create, dar ținând cont și de creativitate și de respectarea standardelor propuse.",
    "Proba surpriză! Proba a fost elaborată pentru a vă pune la bătaie cunoștințele de programare, cu axare pe reverse engineering, web/binary exploitation, dar și abilități în strategie, în care participanții în mod individual vor trebui să rezolve probleme din sfera securității cibernetice, de diferite dificultăți, scopul fiind descoperirea flag-ului.",
  ];

  const [open, setOpen] = useState(false);
  const [buttonIndex, setButtonIndex] = useState(null);

  const handleClickOpen = (index) => () => {
    currentIndex = index;
    setButtonIndex(index);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  console.log(PozeDesign);

  return (
    <Stack
      sx={{
        width: "100%",
      }}
    >
      <Grid
        container
        sx={{
          flexDirection: "row",
          alignItems: "center",
          width: "100%",
        }}
        columnSpacing={2}
        rowGap={2}
      >
        {[...Array(6)].map((_, i) => (
          <Grid sm={4} mdPlus={2} item container key={i}>
            <Button
              variant="contained"
              sx={{
                fontSize: "1rem",
                color: "#F3F3F3",
                backgroundColor: "#303841",
                fontFamily: "Montserrat",
                width: "100%",
                height: "72px",
                borderRadius: "10px",
              }}
              onClick={handleClickOpen(i)}
            >
              {buttonTexts[i] && (
                <span
                  style={{
                    color: "#CCCCCC",
                    font: "Montserrat",
                    lineHeight: "normal",
                  }}
                >
                  {buttonTexts[i]}
                </span>
              )}
            </Button>
          </Grid>
        ))}
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          background: "rgba(0,0,0,0.65)",
          ">*": {
            alignItems: "flex-start !important",
            justifyContent: "flex-start !important",
            ">*": {
              background: "rgba(0,0,0,0) !important",
              boxShadow: "unset !important",
              maxWidth: "calc(100% - 64px) !important",
              "&::-webkit-scrollbar": {
                width: {
                  xs: "0px",
                  md: "26px",
                },
                borderRadius: "13px",
                backgroundClip: "padding-box",
                border: "10px solid transparent",
                height: "15%",
              },
              "&::-webkit-scrollbar-thumb": {
                width: {
                  xs: "0px",
                  md: "26px",
                },
              },
            },
          },
        }}
      >
        <Stack
          sx={{
            maxHeight: "100%",
            gap: "32px",
          }}
        >
          <Stack
            gap="8px"
            sx={{
              alignItems: {
                xs: "flex-start",
                sm: "flex-start",
              },
            }}
          >
            <Grid container>
              <Grid item xs={6}>
                <Stack direction="row" alignItems="center" gap="8px">
                  <CaptureTheFlagIcon
                    sx={{
                      fill: "#2185D5",
                      height: "32px",
                      width: "auto",
                    }}
                  />
                  <Typography variant="h4" fontWeight="800" color="#F3F3F3">
                    {dialogTexts[currentIndex]}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={6} sx={{ textAlign: "right" }}>
                <Button
                  onClick={handleClose}
                  size="medium"
                  color="error"
                  variant="contained"
                  sx={{ marginRight: "20px" }}
                >
                  X
                </Button>
              </Grid>
            </Grid>
            <Typography
              variant="body3"
              fontWeight="500"
              color="#CCCCCC"
              sx={{
                fontFamily: "Montserrat",
                textAlign: { xs: "left", sm: "left", md: "left" },
                width: "70%",
              }}
            >
              {texts[currentIndex]}
            </Typography>
          </Stack>
          <Grid
            container
            sx={{
              height: "100%",
              width: "100%",
              flexDirection: "row",
            }}
            columnSpacing={2}
            rowSpacing={2}
          >
            {currentIndex === 0 &&
              PozeJunior.map((link, index) => (
                <Grid key={index} item container xs={12} sm={6} md={4} lg={3}>
                  <ImaginiProbe linkPoze={link} currentIndex="0" />
                </Grid>
              ))}
            {currentIndex === 1 &&
              PozeDesktop.map((link, index) => (
                <Grid key={index} item container xs={12} sm={6} md={4} lg={3}>
                  <ImaginiProbe linkPoze={link} currentIndex="1" />
                </Grid>
              ))}
            {currentIndex === 2 &&
              PozeDesign.map((link, index) => (
                <Grid key={index} item container xs={12} sm={6} md={4} lg={3}>
                  <ImaginiProbe linkPoze={link} currentIndex="2" />
                </Grid>
              ))}
            {currentIndex === 3 &&
              PozeWeb.map((link, index) => (
                <Grid key={index} item container xs={12} sm={6} md={4} lg={3}>
                  <ImaginiProbe linkPoze={link} currentIndex="3" />
                </Grid>
              ))}
            {currentIndex === 4 &&
              Pozex86.map((link, index) => (
                <Grid key={index} item container xs={12} sm={6} md={4} lg={3}>
                  <ImaginiProbe linkPoze={link} currentIndex="4" />
                </Grid>
              ))}
          </Grid>
        </Stack>
      </Dialog>
    </Stack>
  );
};

export default Probe;
