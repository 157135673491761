import React from "react";
import { Grid, Stack, Typography, Box } from "@mui/material";
//import { ComingSoon } from "../../shared/icons";
import logo1 from "./../../shared/sponsors/AMD.png";
import logo2 from "./../../shared/sponsors/Allied.png";
import logo3 from "./../../shared/sponsors/infineon.png";
import logo4 from "./../../shared/sponsors/mechatronics.png";
import logo5 from "./../../shared/sponsors/SIE.png";
import logo6 from "./../../shared/sponsors/STS.png";
import logo7 from "./../../shared/sponsors/wink.png";
import logo8 from "./../../shared/sponsors/vitesco.png";
import logo9 from "./../../shared/sponsors/temend.png";
import logo10 from "./../../shared/sponsors/Centric.png";
import logo11 from "./../../shared/sponsors/Bitdefender.png";

const Sponsors = () => (
  <Grid
    container
    sx={{
      width: "100%",
      padding: "32px",
      backgroundColor: "#414E62",
      alignItems: "center",
      justifyContent: "center",
      overflow: "hidden",
      gap: "32px",
    }}
  >
    <Grid
      item
      container
      xs={10}
      sx={{ alignItems: "center", justifyContent: "center" }}
    >
      <Typography
        variant="h3"
        component="div"
        fontWeight="800"
        color="white"
        sx={{
          fontFamily: "Montserrat",
          display: "flex",
          flexDirection: "row",
          width: "max-content",
        }}
      >
        ITM
        <Stack component="span" color="#2185D5">
          &nbsp;XII
        </Stack>
        &nbsp;SPONSORS
      </Typography>
    </Grid>
    <Grid
      item
      container
      xs={10}
      sx={{
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Stack
        sx={{
          width: "100%",
          height: "max-content",
          //background: "red",
          flexWrap: "wrap",
          flexDirection: "row",
          rowGap: "16px",
          columnGap: {
            xs: "16px",
            md: "32px",
          },
          alignItems: "center",
          justifyContent: "center",
          ">*": {
            width: {
              xs: "calc(25% - 16px)",
              sm: "calc(20% - 16px)",
              md: "calc(16.6666666667% - 32px)",
              lg: "calc(11.1111111111% - 32px)",
            },
            height: "auto",
          },
        }}
      >
        <Box component="img" src={logo1} />
        <Box component="img" src={logo2} />
        <Box component="img" src={logo3} />
        <Box component="img" src={logo4} />
        <Box component="img" src={logo5} />
        <Box component="img" src={logo6} />
        <Box component="img" src={logo7} />
        <Box component="img" src={logo8} />
        <Box component="img" src={logo9} />
        <Box component="img" src={logo10} />
        <Box component="img" src={logo11} />
      </Stack>
    </Grid>
  </Grid>
);

export default Sponsors;
