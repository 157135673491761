import React from "react";
import { Stack, Typography } from "@mui/material";

const questions = [
  {
    q: "Cine poate participa la concursul IT Marathon?",
    a: "Secţiunile Web Development, Desktop&Mobile Applications, Design sunt adresate atât studenţilor, cât şi liceenilor, iar la secţiunea Junior Dev pot participa doar elevii de liceu.",
  },
  {
    q: "Cum se va desfăşura IT Marathon anul acesta?",
    a: "Probele pe echipe (Web Development, Desktop&Mobile Applications, Design), prezentările și workshop-urile se desfășoară fizic. Junior Dev se va desfăşura la Facultatea de Automatică și Calculatoare din Iași.",
  },
  {
    q: "Ce tipuri de probleme vor fi la secţiunea Junior Dev?",
    a: "Problemele pentru secţiunea Junior Dev sunt de tipul celor de la olimpiada de Informatică.",
  },
  {
    q: "Trebuie sa mă înscriu în echipă?",
    a: "Fiecare participant se poate inscrie atât în echipe de maxim 3 persoane, cât şi individual. Iar la secțiunea Junior Dev și Capture The Flag , înscrierea se face individual.",
  },
  {
    q: "Ce limbaje de programare se pot folosi?",
    a: "Se poate folosi orice limbaj de programare cunoscut.",
  },
  {
    q: "Dacă sunt în clasa a 11-a de exemplu și câștig premiul I la secțiunea Junior Dev, atunci cand voi termina liceul, voi beneficia în continuare de admitere fara examen?",
    a: "Da, atâta vreme cât iei bacalaureatul. 😁",
  },
  {
    q: "Există o taxă de participare?",
    a: "Nu, noi ne dorim să stimulăm competitivitatea şi în niciun caz să o înlesnim! 😁",
  },
  {
    q: "Când şi unde va avea loc Târgul de Cariere?",
    a: "Târgul de Cariere va avea loc la Facultatea de Automatică şi Calculatoare în data de 28 aprilie ,începând cu ora 10:00.",
  },
];

const Question = () => (
  <Stack
    spacing={2}
    sx={{
      overflow: "auto",
    }}
  >
    {questions.map((question, index) => (
      <Stack key={index}>
        <Typography
          variant="h6"
          color="white"
          sx={{
            fontFamily: "Montserrat",
            display: "flex",
            flexDirection: "row",
          }}
        >
          {question.q}
        </Typography>
        <Typography
          variant="body1"
          color="white"
          sx={{
            fontFamily: "Montserrat",
            display: "flex",
            flexDirection: "row",
          }}
        >
          {question.a}
        </Typography>
      </Stack>
    ))}
  </Stack>
);

export default Question;
