import React from "react";
import { Box, Paper } from "@mui/material";
import { Grid, Stack, Typography, Button } from "@mui/material";
import grup3 from "./../../grup3.png";

const Section3 = () => {
  const monthNames = {
    Ianuarie: 0,
    Februarie: 1,
    Martie: 2,
    Aprilie: 3,
    Mai: 4,
    Iunie: 5,
    Iulie: 6,
    August: 7,
    Septembrie: 8,
    Octombrie: 9,
    Noiembrie: 10,
    Decembrie: 11,
  };

  function isLatterDate(dateString) {
    const [day, monthName] = dateString.split(" ");
    const monthIndex = monthNames[monthName];
    const dateToCompare = new Date(currentDate.getFullYear(), monthIndex, day);
    if (currentDate > dateToCompare) {
      return true;
    } else {
      return false;
    }
    return dateToCompare;
  }

  const currentDate = new Date();
  return (
      <Box
        sx={{
          paddingTop: "1rem",
          paddingLeft: "1rem",
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#24292d",
          color: "white",
          fontFamily: "Montserrat",
        }}
      >
        <section class="timeline-section">
          <div class="timeline-items">
            <div class="timeline-item">
              <div
                class="timeline-dot"
                style={{
                  backgroundColor: isLatterDate("6 Aprilie")
                    ? "red"
                    : "lightgreen",
                }}
              ></div>
              <div
                class="timeline-date"
                style={{
                  color: isLatterDate("6 Aprilie") ? "red" : "lightgreen",
                }}
              >
                6 Aprilie
              </div>
              <div class="timeline-content">
                <h3>Start înscrieri!</h3>
                <p>
                  Se dă startul înscrierilor! Fiecare candidat/echipă poate folosi formularul de înscrieri pentru a candida la ediția actuală de ITMarathon!{" "}
                </p>
              </div>
            </div>
            <div class="timeline-item">
              <div
                class="timeline-dot"
                style={{
                  backgroundColor: isLatterDate("6 Aprilie")
                    ? "red"
                    : "lightgreen",
                }}
              ></div>
              <div
                class="timeline-date"
                style={{
                  color: isLatterDate("21 Aprilie") ? "red" : "lightgreen",
                }}
              >
                21 Aprilie
              </div>
              <div class="timeline-content">
                <h3>Stop înscrieri!</h3>
                <p>
                  Înscrierile s-au terminat!{" "}
                </p>
              </div>
            </div>
            <div class="timeline-item">
              <div
                class="timeline-dot"
                style={{
                  backgroundColor: isLatterDate("27 Aprilie")
                    ? "red"
                    : "lightgreen",
                }}
              ></div>
              <div
                class="timeline-date"
                style={{
                  color: isLatterDate("27 Aprilie") ? "red" : "lightgreen",
                }}
              >
                27 Aprilie
              </div>
              <div class="timeline-content">
                <h3>Încep probele!</h3>
                <p>
                  Se dă startul probelor specifice evenimentului!{" "}
                </p>
              </div>
            </div>
            <div class="timeline-item">
              <div
                class="timeline-dot"
                style={{
                  backgroundColor: isLatterDate("28 Aprilie")
                    ? "red"
                    : "lightgreen",
                }}
              ></div>
              <div
                class="timeline-date"
                style={{
                  color: isLatterDate("28 Aprilie") ? "red" : "lightgreen",
                }}
              >
                28 Aprilie
              </div>
              <div class="timeline-content">
                <h3>Târgul de job-uri InfoIT</h3>
                <p>
                În cadrul Târgului de Cariere „InfoIT”  invităm companiile din domeniul IT să prezinte studenților informații despre ofertele de pe piața muncii, internship-uri și despre ultimele tehnologii utilizate în dezvoltarea produselor sau serviciilor oferite.{" "}
                </p>
              </div>
            </div>
          </div>
        </section>
      </Box>
  );
};

export default Section3;
