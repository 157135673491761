import React from "react";
import {
  Stack,
  TextField,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Radio,
  FormLabel,
} from "@mui/material";

const Coechipier2 = () => (
  <Stack>
    <TextField
      required
      name="Nume (coechipier 2)"
      label="16. Nume & Prenume Coechipier 2:"
      fullWidth
      variant="standard"
    />
    <TextField
      required
      name="Telefon (coechipier 2)"
      label="17. Telefon Coechipier 2:"
      fullWidth
      variant="standard"
    />
    <TextField
      required
      name="Email (coechipier 2)"
      label="18. Email Coechipier 2:"
      fullWidth
      variant="standard"
    />
    <TextField
      required
      name="Localitate (coechipier 2)"
      label="19. Localitate Coechipier 2:"
      fullWidth
      variant="standard"
    />
    <TextField
      required
      name="Facultate/Liceu (coechipier 2)"
      label="20. Facultate/Liceu Coechipier 2"
      fullWidth
      variant="standard"
    />

    <FormControl component="fieldset">
      <FormLabel component="legend">
        21. Coechipierul 2 are nevoie de cazare:
      </FormLabel>
      <RadioGroup row aria-label="cazare" name="cazare1">
        <FormControlLabel name="Cazare (coechipier 2)" value="26 Aprilie" control={<Radio />} label="26 Aprilie" />
        <FormControlLabel name="Cazare (coechipier 2)" value="27 Aprilie" control={<Radio />} label="27 Aprilie" />
        <FormControlLabel name="Cazare (coechipier 2)" value="Ambele zile" control={<Radio />} label="Ambele zile" />
        <FormControlLabel
          name = "Cazare (coechipier 2)"
          value="Nu am nevoie de cazare"
          control={<Radio />}
          label="Nu am nevoie de cazare"
        />
      </RadioGroup>
    </FormControl>
  </Stack>
);

export default Coechipier2;
