import React from "react";
import { Grid, Stack, Typography, Button } from "@mui/material";
import grup3 from "./../../grup3.png";
import pozaGrup from "./../../shared/images/gradientpoza.png";
import NavLinks from "./components/NavLinks";
import Socials from "../Socials";
import Inscrieri from "../Modals/Inscrieri";

import { NavLink } from "react-router-dom";

const Section1 = () => {
  return (
    <Grid
      container
      sx={{
        height: "100vh",
        backgroundImage: `linear-gradient(45deg, rgba(0,0,0,0.9) 0%, rgba(0,0,0,0.48) 100%), url(${pozaGrup})`,
        backgroundSize: "cover",
        backgroundPositionY: "45%",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: {
          xs: "8px 0",
          sm: "1em 0",
        },
        width: "100%",
        overflow: "hidden",
      }}
    >
      <Grid
        container
        item
        xs={10}
        md={10}
        sx={{
          position: "relative",
          overflow: "hidden",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <NavLinks />
        <Stack
          direction="column"
          sx={{
            alignItems: {
              xs: "center",
              md: "flex-start",
            },
            gap: "8px",
          }}
        >
          <Stack
            sx={{
              width: "100%",
              flexDirection: "row",
              alignItems: {
                xs: "center",
                md: "flex-start",
              },
              justifyContent: {
                xs: "center",
                md: "flex-start",
              },
            }}
          >
            <Typography
              color="#F3F3F3"
              fontWeight="800"
              sx={{
                fontFamily: "Montserrat",
                fontSize: {
                  xs: "24px",
                  sm: "32px",
                  md: "40px",
                },
              }}
            >
              IT MARATHON&nbsp;
            </Typography>
            <Typography
              color="#2185D5"
              fontWeight="800"
              sx={{
                fontFamily: "Montserrat",
                fontSize: {
                  xs: "24px",
                  sm: "32px",
                  md: "40px",
                },
              }}
            >
              XII
            </Typography>
          </Stack>
          <Stack
            sx={{
              maxWidth: { xs: "80%", md: "60%", lg: "40%" },
            }}
          >
            <Typography
              variant="body3"
              fontWeight="500"
              color="#CCCCCC"
              sx={{
                fontFamily: "Montserrat",
                textAlign: { xs: "center", md: "left" },
              }}
            >
              IT Marathon este concurs de nivel național adresat studenților și
              elevilor de liceu pasionați de IT, crescând în fiecare an ca număr
              de participanți și de secțiuni, a ajuns în 2024 la a XII-a sa
              ediție.
            </Typography>
          </Stack>
          <Socials hoverColor="" />
          {/* <Button
            size="medium"
            variant="contained"
            style={{ marginTop: "20px" }}
            component={NavLink}
            to="/inscrieri"
          >
            Înscrie-te!
          </Button> */}
        </Stack>
      </Grid>
    </Grid>
  );
};

export default Section1;
