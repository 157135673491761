import React from "react";
import { Stack, Typography, Box } from "@mui/material";
import { PcIcon } from "../../../shared/icons";
import IlustratieItm from "./../../../shared/images/itmPoza.png";

const SectionTitle = () => (
  <Stack
    sx={{
      alignItems: "center",
      flexDirection: "column",
    }}
  >
    <Stack
      direction="column"
      sx={{
        gap: "8px",
      }}
    >
      <Stack
        sx={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: {
            xs: "center",
            mdPlus: "space-between",
          },
          width: "100%",
        }}
      >
        <Stack
          sx={{
            alignItems: {
              xs: "center",
              mdPlus: "flex-start",
            },
          }}
        >
          <Typography
            variant="h3"
            component="div"
            fontWeight="800"
            color="white"
            sx={{
              fontFamily: "Montserrat",
              display: "flex",
              flexDirection: "row",
            }}
          >
            Probe ITM
            <Stack component="span" color="#2185D5">
              &nbsp;XII
            </Stack>
          </Typography>
          <Typography
            variant="body3"
            fontWeight="500"
            color="#CCCCCC"
            sx={{
              width: { xs: "80%", mdPlus: "50%" },
              textAlign: { xs: "center", mdPlus: "left" },
            }}
          >
            Cu deosebită plăcere vă prezentăm a XII-a ediție a evenimentului nostru, IT Marathon, reprezentativ prin dinamica pe care o abordează, cât și prin provocările pe care le propune tuturor celor care participă, indiferent de rolul pe care și l-au asumat (voluntar, juriu, concurent). Cu fiecare an care a trecut de la înființarea sa, această competiție a reușit să surprindă din ce în ce mai mult creativitatea și experiența din domeniul IT ale tinerilor programatori, într-un mediu de colaborare și competiție constructivă.
          </Typography>
        </Stack>
        {/* <Box
          component="img"
          src={IlustratieItm}
          sx={{ width: "25%", height: "auto",sd }}
        /> */}
        {/* <PcIcon
          sx={{
            width: "20%",
            height: "auto",
            display: {
              xs: "none",
              md: "flex",
            },
          }}
        /> */}
      </Stack>
    </Stack>
  </Stack>
);

export default SectionTitle;
