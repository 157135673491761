import React, { useState } from "react";
import { Stack, Paper } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Section1, Section2, Section3 } from "./components";

import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from "@mui/material/styles";

import grup3 from "./grup3.png";
import grid1 from "./grid.png";

import { ReactComponent as PcSvg } from "./pc.svg";
import Footer from "./components/Footer";
import { Router } from "react-router-dom";
import Sponsors from "./components/Sponsors/Sponsors";

const Home = () => {
  let theme = createTheme({
    typography: {
      fontFamily: "Arial Black, sans-serif",
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        mdPlus: 1060,
        lg: 1200,
        xl: 1536,
      },
    },
  });
  theme = responsiveFontSizes(theme);

  const [open, setOpen] = useState(false);
  const [buttonIndex, setButtonIndex] = useState(null);

  const isSmallScreen = useMediaQuery("(max-width:600px)");

  const dialogTexts = [
    "Carrots",
    "Potato",
    "Text 3",
    "Text 4",
    "Text 5",
    "Mata",
  ];
  const buttonTexts = [
    "Junior Dev",
    "Mobile & Desktop",
    "Design",
    "Web Development",
    "CPU Design & Modeling",
    "Capture the Flag",
  ];

  const handleClickOpen = (index) => () => {
    setButtonIndex(index);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Stack direction="column">
        <Paper square>
          <Section1 />
          <div id="probe">
          <Section2 />
          </div>
          <Section3 />
          <Sponsors />
          <div id="contact">
          <Footer />
          </div>
        </Paper>
      </Stack>
    </ThemeProvider>
  );
};

export default Home;
