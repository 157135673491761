import React from "react";
import { Stack, Typography } from "@mui/material";
//import PozaTest from "./../../../grup3.png";
import { useEffect } from "react";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const transparentPixel =
  "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7";

const dialogTexts = [
  "Junior Dev",
  "Mobile & Desktop",
  "Design",
  "Web Development",
  "CPU Design & Modeling",
  "Capture The Flag",
];

const ImaginiProbe = ({ linkPoze, currentIndex }) => {
  useEffect(() => {
    //console.log(currentIndex);
  }, []);

  return (
    <Stack
      sx={{
        width: "100%",
        aspectRatio: "1/1",
        height: "auto",
        justifyContent: "flex-end",
        padding: "0px 0px 4px 4px",
        backgroundImage: `url(${linkPoze})`,
        overflow: "hidden",
        cursor: "pointer",
        position: "relative",
        backgroundSize: "cover",
        backgroundPosition: "center",
        zIndex: "2",
        "&:hover": {
          "> div:nth-of-type(1)": {
            transform: "translate(0, 0)",
          },
          "> div:nth-of-type(2)": {
            transform: "translate(0, 0)",
          },
        },
      }}
    >
      {/* <LazyLoadImage
        alt={transparentPixel}
        src={linkPoze}
        effect="blur"
        width="100%"
        height="auto"
      /> */}
      <Stack
        sx={{
          transition: "ease-in-out all 0.3s",
          transform: "translate(-120%, 0)",
          zIndex: "2",
          width: "max-content",
        }}
      >
        <Typography
          fontWeight="600"
          color="#f3f3f3"
          flexDirection="row"
          sx={{
            fontFamily: "Montserrat",
            textAlign: "left",
            fontSize: "1rem",
            display: "flex",
          }}
        >
          <Stack color="#2185D5">@</Stack>ITM 2024
        </Typography>
        <Typography
          fontWeight="600"
          color="#CCCCCC"
          sx={{
            fontFamily: "Montserrat",
            textAlign: "left",
            fontSize: "1rem",
          }}
        >
          {dialogTexts[currentIndex]}
        </Typography>
      </Stack>
      <Stack
        sx={{
          background:
            "linear-gradient(0deg, rgba(0,0,0,0.8) 10%, rgba(0,0,0,0) 100%)",
          width: "100%",
          height: "100%",
          position: "absolute",
          top: "0%",
          left: "0%",
          zIndex: "0",
          transform: "translate(0, 100%)",
          transition: "ease-in-out all 0.1s",
        }}
      />
    </Stack>
  );
};

export default ImaginiProbe;
