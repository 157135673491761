import React from "react";
import { createSvgIcon } from "@mui/material";

const LsacIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 886.75 259.31">
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path d="M71.66,126q0-14.51,0-29a12.64,12.64,0,0,0-.12-2.39,5,5,0,0,0-5.15-4.15c-3.92-.12-7.84,0-11.75-.09a12.52,12.52,0,0,1-6.05-1.19c-4.17-2.24-6.45-5.69-6.49-10.42Q42,66,42.1,53.32a11.13,11.13,0,0,1,6.78-10.45,9.48,9.48,0,0,1,3.91-.94c9-.08,18.08-.14,27.11,0a10.92,10.92,0,0,1,10.28,8.29A15.44,15.44,0,0,1,90.56,54c0,3.68,0,7.35,0,11a8.92,8.92,0,0,0,.58,3.26,4.69,4.69,0,0,0,4.08,2.92,19.41,19.41,0,0,0,2.16,0h57.1c.48,0,1,0,1.43,0,3.32-.12,5.4-2.12,5.47-5.42.07-4.23,0-8.47,0-12.71A11.2,11.2,0,0,1,169.7,42.3a12.27,12.27,0,0,1,3.31-.42c8.4,0,16.79,0,25.19,0A11.55,11.55,0,0,1,209.93,53.5c0,9.5.27,19,.29,28.51q.06,44.47,0,89c0,9.34-.23,18.69-.29,28a11.53,11.53,0,0,1-11.76,11.61c-8.31-.15-16.64-.17-24.95,0a11.43,11.43,0,0,1-11.78-11.78c.11-1.75.05-3.52,0-5.28a5.18,5.18,0,0,0-5.24-5.39c-.79-.06-1.59,0-2.39,0H98.38c-.72,0-1.44,0-2.16,0-3.66.17-5.51,2-5.62,5.75-.07,2,0,4-.08,6a11.16,11.16,0,0,1-8.25,10.32,13.15,13.15,0,0,1-3.32.36c-8.4,0-16.8-.13-25.19.05a11.52,11.52,0,0,1-11.6-10.5c0-.64-.1-1.27-.1-1.91,0-7.83,0-15.67,0-23.5a12.24,12.24,0,0,1,1.63-6.89,11.53,11.53,0,0,1,10-5.66c3.44-.05,6.88,0,10.32,0a19.56,19.56,0,0,0,2.86-.21,5.21,5.21,0,0,0,4.67-5c.08-.8,0-1.6,0-2.4V126Z" />
        <path d="M706.09,215.94v5c-6.45-.31-12.79-.8-19.14-.89s-12.63.12-18.94.38-12.75.73-19.34,1.12v-4.93c1.46-.35,2.94-.63,4.38-1.08a58.56,58.56,0,0,0,6.1-2.11,6.09,6.09,0,0,0,3.86-6.07,29.93,29.93,0,0,0-1.88-9.84c-3.09-8.9-6.29-17.76-9.49-26.62-1.65-4.58-3.44-9.12-5-13.72a3.11,3.11,0,0,0-3.23-2.48c-8.79-.26-17.57-.69-26.35-.9-6.56-.15-13.12-.24-19.67,0-6.22.23-12.42.94-18.63,1.37a2.72,2.72,0,0,0-2.59,2c-4.84,12.34-9.75,24.65-14.58,37a52.57,52.57,0,0,0-1.94,6.17c-1.31,5.08.41,9.1,5,11.74,3.31,1.89,7,2.71,10.67,3.47,2.25.46,4.51.86,6.85,1.3v4.69c-9.26-.42-18.4-1.1-27.54-1.18s-18.37.44-27.76.7v-4.25c.69-.23,1.5-.51,2.33-.75a25.54,25.54,0,0,0,15.61-12.24,254.77,254.77,0,0,0,13-26.19c6-13.93,11.67-28,17.53-42,9.55-22.83,19.28-45.6,28.65-68.51,4.08-10,7.49-20.21,11.17-30.33a29.54,29.54,0,0,0,.92-3.47c.5-2.21.54-2.27,2.74-2.57.79-.1,1.59-.19,2.39-.25a3.65,3.65,0,0,1,.81.16c.2.55.45,1.13.62,1.73,4.62,16.73,10.73,32.94,16.86,49.15C651.28,112.8,663,144,675,175.2c3.37,8.79,7.29,17.37,11.08,26a68.73,68.73,0,0,0,3.88,6.88,12.56,12.56,0,0,0,7.15,5.71c2.27.73,4.63,1.2,6.95,1.77C704.63,215.71,705.25,215.79,706.09,215.94ZM611.47,70.55c-10.57,24.24-19.69,48.91-29.28,73.58a9.3,9.3,0,0,0,1.4.39c4.14.36,8.28,1,12.42,1,12.63.08,25.25-.05,37.88-.15,2.51,0,5-.27,8-.44C632.29,119.7,622.92,94.78,611.47,70.55Z" />
        <path d="M510.76,33.79c-.35,3.36-.72,6.59-1,9.83-.41,4.22-1.06,8.43-1.08,12.65,0,3.89.69,7.79,1.05,11.68.22,2.46.39,4.92.61,7.71l-4.54.49c-.24-1.39-.5-2.62-.67-3.85a33.94,33.94,0,0,0-13.33-23,48.19,48.19,0,0,0-19.22-8.83A73.36,73.36,0,0,0,443,39.65,32.33,32.33,0,0,0,431.43,44a20.66,20.66,0,0,0-6.55,6.24c-3.77,5.82-6.75,12-7.75,18.93-1.66,11.53,1,21.72,9.75,29.92a41.66,41.66,0,0,0,12.1,8.09c8.73,3.76,17.55,7.3,26.26,11.1,7.76,3.38,15.51,6.81,23.1,10.54,8.79,4.33,15.68,11,21.33,18.9a41.23,41.23,0,0,1,7.22,20.07c1,9.41-.13,18.48-4.41,27.06-4.36,8.75-11.1,15.12-19.56,19.82a67.75,67.75,0,0,1-23.55,7.73c-15.6,2.26-31.1,1.27-46.51-1.71-4.86-.94-9.66-2.17-14.49-3.25a19.31,19.31,0,0,0-2.6-.43c-2.39-.2-3.24.51-3.46,2.83,0,.4-.05.8-.08,1.47a17.26,17.26,0,0,1-6.06-.35c-.05-16.5-3.55-32.44-8.06-48.51l4.89-1.2c1.11,3.29,2.1,6.31,3.15,9.31.93,2.63,1.85,5.28,2.93,7.85a26.31,26.31,0,0,0,12.11,13.46c11.51,6.08,23.55,10.77,36.51,12.61,12.17,1.73,24,.95,35.44-4.25a21.55,21.55,0,0,0,12.63-14.74,47.54,47.54,0,0,0-.34-26.49,33.4,33.4,0,0,0-12-17.19,78.81,78.81,0,0,0-18.25-10c-7.32-3-14.72-5.77-22.07-8.69A152.93,152.93,0,0,1,423,123.56,56.4,56.4,0,0,1,406,108.93c-5.85-7.65-8-16.43-7.92-25.85.19-21.94,13.74-39.91,35.19-46.88a83.07,83.07,0,0,1,22.87-3.78A143.94,143.94,0,0,1,492.8,36c4.85,1.08,9.35.78,13.51-2.12C507.73,32.85,509.15,33.15,510.76,33.79Z" />
        <path d="M878.84,76.42c-3.15-.56-4.09-1.49-4.39-4.33-.33-3-.53-6-.87-9.06a12.81,12.81,0,0,0-4.65-8.65,44.08,44.08,0,0,0-5.8-4.23c-10.82-6.36-22.38-10.82-34.94-11.74C798,36.18,774.54,47.51,759.08,74c-6.22,10.62-9.59,22.24-11.18,34.41a131.15,131.15,0,0,0-.42,30.61c2.16,20.93,10.18,39.25,25.45,53.9,13.35,12.8,29.4,20.74,47.83,22.91,14.64,1.73,28.54-.87,40.62-10.13a44.7,44.7,0,0,0,7.88-7.67A122.22,122.22,0,0,0,880,181.59a24,24,0,0,1,2.15-3.15,2.59,2.59,0,0,1,3.07-.71,2.22,2.22,0,0,1,1.48,2.48,15,15,0,0,1-.42,3.07c-2.17,7.85-4.39,15.68-6.59,23.52-.44,1.54-1,3.06-1.29,4.61a3.14,3.14,0,0,1-2.59,2.69C868,216,860.23,218.16,852.36,220A120.34,120.34,0,0,1,814,222.77c-36.18-3.49-64.6-19.55-82.18-52.33A86.18,86.18,0,0,1,722.26,137a112.13,112.13,0,0,1,2.31-36.43c4.62-19.25,15.32-34.58,30.44-47,11-9.05,23.7-14.73,37.25-18.68a122.6,122.6,0,0,1,40.53-4.81,109.71,109.71,0,0,1,31.61,6.39c3.67,1.31,7.52,2.13,11.3,3.11a10.86,10.86,0,0,0,5.46,0,13.5,13.5,0,0,1,2.6-.39c2.21-.1,2.88.72,2.36,2.9-1.49,6.21-3.11,12.39-4.47,18.63-.93,4.28-1.51,8.65-2.26,13C879.23,74.58,879,75.5,878.84,76.42Z" />
        <path d="M374,182.48l4.61.62c.42,5.3-7.15,32.8-10.91,39.67-1.85-.14-3.83-.23-5.78-.45a239.72,239.72,0,0,0-26.12-1q-51.93-.13-103.87-.17H228.7c-.14-1.65-.27-3-.41-4.68,1.07-.29,2.06-.53,3-.82,2.6-.77,5.22-1.49,7.78-2.37a12,12,0,0,0,7.29-7.16,32.7,32.7,0,0,0,2.34-11c.4-7.5.77-15,.82-22.51.17-24.3.18-48.6.26-72.89,0-9.83.21-19.66.15-29.49,0-5.9,0-11.84-1.19-17.67a34,34,0,0,0-1.54-5.52,9.18,9.18,0,0,0-7.09-6.17c-2.66-.5-5.36-.79-8-1.18l-1.54-.18V34.44c.85,0,1.7-.13,2.54-.12,8.72.1,17.43.28,26.15.3,10.63,0,21.26-.12,31.9-.18h4.68a16.44,16.44,0,0,1-.17,5.59c-2.65.35-5.26.63-7.85,1.07a57.65,57.65,0,0,0-7,1.5c-4.08,1.24-6.81,4-7.45,8.25a124.45,124.45,0,0,0-1.4,14.53q-.94,29.36-1.6,58.71c-.24,10.31-.35,20.62-.48,30.93-.08,6.31-.19,12.62-.09,18.93.12,7.75.34,15.5.74,23.24a55.94,55.94,0,0,0,1.44,8.47,5.27,5.27,0,0,0,4.1,4.26,53.05,53.05,0,0,0,8.66,1.71,275.68,275.68,0,0,0,36.92,0,131.84,131.84,0,0,0,32.75-6.84c9.82-3.27,15.73-9.94,18.73-19.57C373.36,184.41,373.66,183.59,374,182.48Z" />
        <path d="M30.44,156.67c-5.6,0-11.2,0-16.8,0-7.52,0-13.43-5.54-13.53-13q-.22-17.39,0-34.77A13.25,13.25,0,0,1,13.57,95.74c7.11-.1,14.23,0,21.35,0,4,0,8,0,12,0,8.36.05,14.09,5.63,14.14,13.94q.11,16.66,0,33.33c0,6.58-3.61,11.35-9.8,13.21a12.79,12.79,0,0,1-3.54.46C42,156.69,36.19,156.67,30.44,156.67Z" />
        <path d="M125,.06c5.91,0,11.83-.11,17.74,0a12.59,12.59,0,0,1,11.79,9,15.45,15.45,0,0,1,.7,4.47c.06,11.19,0,22.37.05,33.56a13.68,13.68,0,0,1-13.81,13.79c-11.19-.2-22.39,0-33.58-.07-7.69,0-13.43-5.48-13.55-13.2-.16-11.51-.19-23,0-34.52A12.91,12.91,0,0,1,107.75,0C113.5.14,119.26,0,125,0Z" />
        <path d="M126.21,259.3c-5.68,0-11.35,0-17,0a12.82,12.82,0,0,1-13-12.82c-.08-4.23,0-8.47,0-12.7,0-7.35,0-14.7,0-22.05A13,13,0,0,1,105.92,199a14.14,14.14,0,0,1,3.56-.39c11.35,0,22.7.13,34.05-.08,6.45-.11,13.5,5.36,13.41,13.34-.14,11.42-.08,22.84,0,34.27A13,13,0,0,1,146.8,259a12.69,12.69,0,0,1-2.61.25C138.2,259.31,132.2,259.3,126.21,259.3Z" />
      </g>
    </g>
  </svg>,
  "LsacIcon"
);

export default LsacIcon;
