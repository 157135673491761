import React from "react";
import { createSvgIcon } from "@mui/material";

const FacebookIcon = createSvgIcon(
  <svg
    width="100"
    href="https://www.google.com" target="_blank" rel="noopener noreferrer"
    height="100"
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M100 50.3062C100 22.5187 77.6125 -0.0062561 50 -0.0062561C22.375 -6.10322e-06 -0.0124512 22.5187 -0.0124512 50.3125C-0.0124512 75.4187 18.275 96.2312 42.175 100.006V64.85H29.4875V50.3125H42.1875V39.2187C42.1875 26.6125 49.6563 19.65 61.075 19.65C66.55 19.65 72.2688 20.6312 72.2688 20.6312V33.0062H65.9625C59.7563 33.0062 57.8188 36.8875 57.8188 40.8687V50.3062H71.6813L69.4688 64.8437H57.8125V100C81.7125 96.225 100 75.4125 100 50.3062Z" />
  </svg>,
  
  "FacebookIcon"
);

export default FacebookIcon;
