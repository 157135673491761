import React from "react";
import {
  Stack,
  TextField,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Radio,
  FormLabel,
} from "@mui/material";

const Coechipier1 = () => (
  <Stack>
    <TextField
      required
      name="Nume echipă"
      label="9. Nume echipă:"
      fullWidth
      variant="standard"
    />
    <TextField
      required
      name="Nume (coechipier 1)"
      label="10. Nume & Prenume Coechipier 1:"
      fullWidth
      variant="standard"
    />
    <TextField
      required
      name="Telefon (coechipier 1)"
      label="11. Telefon Coechipier 1:"
      fullWidth
      variant="standard"
    />
    <TextField
      required
      name="Email (coechipier 1)"
      label="12. Email Coechipier 1:"
      fullWidth
      variant="standard"
    />
    <TextField
      required
      name="Localitate (coechipier 1)"
      label="13. Localitate Coechipier 1"
      fullWidth
      variant="standard"
    />
    <TextField
      required
      name="Facultate/Liceu (coechipier 1)"
      label="14. Facultate/Liceu Coechipier 1"
      fullWidth
      variant="standard"
    />

    <FormControl component="fieldset">
      <FormLabel component="legend">
        15. Coechipierul 1 are nevoie de cazare:
      </FormLabel>
      <RadioGroup row aria-label="cazare" name="cazare1">
        <FormControlLabel name = "Cazare (coechipier 1)" value="26 Aprilie" control={<Radio />} label="26 Aprilie" />
        <FormControlLabel name = "Cazare (coechipier 1)" value="27 Aprilie" control={<Radio />} label="27 Aprilie" />
        <FormControlLabel name = "Cazare (coechipier 1)" value="Ambele zile" control={<Radio />} label="Ambele zile" />
        <FormControlLabel
          name = "Cazare (coechipier 1)"
          value="Nu am nevoie de cazare"
          control={<Radio />}
          label="Nu am nevoie de cazare"
        />
      </RadioGroup>
    </FormControl>
  </Stack>
);

export default Coechipier1;
