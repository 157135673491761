import React from "react";
import { createSvgIcon } from "@mui/material";

const CaptureTheFlagIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 382.98 382.98">
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path d="M89.82,383c-5.7-2.58-8.14-7.33-9.76-13.16Q40.82,228.73,1.34,87.7A36.14,36.14,0,0,0,0,84.31V77.58c.34-.79.69-1.57,1-2.36,2.77-6.91,9.16-10.87,16.91-10.49,6.75.33,12.31,4.72,14.3,11.74q10.88,38.44,21.59,76.94,29,103.93,58.05,207.89a38.64,38.64,0,0,1,1.24,5.07,12.62,12.62,0,0,1-8.83,14.25l-7,2.36Z" />
        <path d="M97.21,259.83c-3.57-12.76-7-25-10.39-37.17C73,173.25,59.48,123.78,45.19,74.52c-1.94-6.69-.39-9.9,4.21-13.9,16.23-14.08,35.08-22,56.2-25s42.05-1.51,63.06.4c25.93,2.36,51.8,5.89,78,4.13,30.94-2.09,57.59-13.12,78-37.24.48-.57.9-1.33,1.53-1.61,1.38-.61,3.06-1.59,4.27-1.23.9.26,1.83,2.42,1.74,3.67-.33,5-1,9.91-1.66,14.84a888.15,888.15,0,0,1-21.24,106.18c-1,3.84.09,6.55,3.05,9.21a303.24,303.24,0,0,0,66.38,45.58c3.69,1.85,5,4.48,3.91,8.1-.92,3-2.06,6.26-4,8.62-17.82,21.29-40.94,32.74-68.26,36.29-22.58,2.94-45.05.93-67.54-1.17-24.44-2.29-48.84-5.39-73.49-3.44-27,2.12-51,11-70.42,30.52C98.51,258.83,98.1,259.1,97.21,259.83Z" />
      </g>
    </g>
  </svg>,
  "CaptureTheFlagIcon"
);

export default CaptureTheFlagIcon;
