import React, { useState } from "react";
import { Stack } from "@mui/material";
import HamburgerIcon from "../../../shared/icons/HamburgerIcon";
import { HashLink } from 'react-router-hash-link';

import { Link } from "react-router-dom";

const NavLinks = () => {
  const [openLinks, setOpenLinks] = useState(false);

  const openHamburger = () => {
    setOpenLinks(!openLinks);
  };

  return (
    <>
      <Stack onClick={openHamburger}>
        <HamburgerIcon
          sx={{
            height: "24px",
            fill: "#F3F3F3",
            width: "auto",
            position: "absolute",
            top: "0",
            right: "0",
            display: {
              xs: "flex",
              sm: "none",
            },
          }}
        />
      </Stack>
      <Stack
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          justifyContent: "flex-end",
          flexDirection: {
            xs: "column",
            sm: "row",
          },
          gap: {
            xs: "16px",
            sm: "32px",
          },
          color: "#F3F3F3",
          fontWeight: "500",
          textDecoration: "none",
          height: {
            xs: openLinks ? "max-content" : "0px",
            sm: "max-content",
          },
          marginTop: {
            xs: "36px",
            sm: "0",
          },
          transition: "all 0.2s ease-in-out",
          overflow: "hidden",
          textAlign: "left",
          ">*": {
            textAlign: "left",
            color: "#F3F3F3 !important",
            textDecoration: "none !important",
            cursor: "pointer",
            flexDirection: "column",
            gap: "4px",
            display: "flex",
            fontFamily: "Montserrat !important",
            alignItems: {
              xs: "flex-end",
              sm: "center",
            },
            "&:hover:after": {
              width: "100%",
            },
            "::after": {
              content: '" "',
              width: "0%",
              height: "2px",
              background: "#2185D5",
              transition: "0.2s ease-in-out all",
            },
          },
          display: {
            xs: "flex",
            sm: "flex",
          },
        }}
      >
        {/* <Link to="/inscrieri">Înscrieri</Link> */}
        <HashLink smooth to='#probe'> Probe </HashLink>
        <Link to="/faq">FaQ</Link>
        <HashLink smooth to='#contact'>Contact</HashLink>
      </Stack>
    </>
  );
};

export default NavLinks;
