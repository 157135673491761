import React from "react";
import { Grid, Typography, Stack } from "@mui/material";
import Question from "./components/Question";
import Footer from "../../components/Footer";

const FaQ = () => (
  <Grid
    container
    sx={{
      height: "100vh",
      width: "100dvw",
      background: "#303841",
      alignItems: "center",
      justifyContent: "center",
      gap: "32px",
      overflow: "auto",
      position: "relative",
    }}
  >
    <Grid
      item
      container
      xs={12}
      alignItems="center"
      justifyContent="center"
      sx={{
        marginTop: "32px",
      }}
    >
      <Typography
        variant="h3"
        component="div"
        fontWeight="800"
        color="white"
        sx={{
          fontFamily: "Montserrat",
          display: "flex",
          flexDirection: "row",
        }}
      >
        FaQ
        <Stack component="span" color="#2185D5">
          &nbsp;ITM
        </Stack>
      </Typography>
    </Grid>
    <Grid
      item
      container
      xs={12}
      sx={{
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
        overflowY: "auto",
        padding: "32px",
      }}
    >
      <Question />
    </Grid>
    <Grid
      item
      container
      xs={12}
      sx={{
        width: "calc(100% + 64px)",
        maxWidth: "unset",
        background: "red",
        ">*": {
          width: "100%",
        },
      }}
    >
      <Footer />
    </Grid>
  </Grid>
);

export default FaQ;