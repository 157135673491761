import React from "react";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import { Box } from "@mui/material";
// import useMediaQuery from "@mui/material/useMediaQuery";
import SectionTitle from "./components/SectionTitle";
import Probe from "./components/Probe";
import IlustratieItm from "./../../shared/images/itmPoza.png";

function Section2() {
  // const isSmallScreen = useMediaQuery("(max-width:600px)");

  return (
    <Stack
      sx={{
        height: "max-content",
        backgroundColor: "#414E62",
        zIndex: 1,
        backgroundSize: "34% 100%",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Stack>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          sx={{
            padding: {
              xs: "32px",
              mdPlus: "32px 0",
            },
          }}
        >
          <Grid
            item
            container
            xs={12}
            mdPlus={10}
            sx={{
              position: "relative",
              minHeight: {
                xs: "max-content",
                mdPlus: "412px",
              },
              //justifyContent: "space-between",
              flexDirection: "column",
              justifyContent: {
                xs: "center",
                md: "center",
              },
              gap: {
                xs: "32px",
                mdPlus: "64px",
              },
            }}
          >
            <Box
              component="img"
              src={IlustratieItm}
              sx={{
                width: "auto",
                height: "110%",
                position: "absolute",
                right: "0",
                display: {
                  xs: "none",
                  mdPlus: "flex",
                },
              }}
            />
            <SectionTitle />
            <Probe />
          </Grid>
        </Grid>
      </Stack>
    </Stack>
  );
}

export default Section2;
