/* eslint-disable no-unused-vars */
import React from "react";
import { Stack, Container, Link } from "@mui/material";
import { FacebookIcon, InstagramIcon, WebsiteIcon } from "../../shared/icons";

const Socials = (Props) => (
  <Stack
    sx={{
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      gap: "16px",
      ">*": {
        ">*": {
          height: "24px !important",
          width: "24px !important",
          fill: Props.normalColor || "#F3F3F3",
          transition: "all 0.2s ease-in-out",
          cursor: "pointer",
          "&:hover": {
            scale: "1.05",
            fill: Props.hoverColor || "#2185D5",
          },
        },
      },
    }}
  >
    <Link href="https://www.facebook.com/profile.php?id=100090604756637" target="_blank">
      <FacebookIcon />
    </Link>
    <Link href="https://www.instagram.com/lsaciasi/" target="_blank">
      <InstagramIcon />
    </Link>
    <Link href="https://www.lsaciasi.ro/" target="_blank">
      <WebsiteIcon />
    </Link>
  </Stack>
);

export default Socials;
