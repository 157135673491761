import React from "react";
import { createSvgIcon } from "@mui/material";

const HamburgerIcon = createSvgIcon(
  <svg
    width="17"
    height="12"
    viewBox="0 0 17 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1.53691 0.6521C1.39039 0.652064 1.24529 0.6809 1.10991 0.736959C0.974526 0.793019 0.851517 0.875203 0.747907 0.978817C0.644296 1.08243 0.562115 1.20544 0.506058 1.34083C0.450001 1.47621 0.421168 1.62132 0.421204 1.76785C0.42142 2.0636 0.539072 2.34715 0.748293 2.55618C0.957513 2.7652 1.24118 2.88258 1.53691 2.88251H14.9225C15.218 2.88229 15.5014 2.76478 15.7104 2.55579C15.9194 2.3468 16.0369 2.06341 16.0371 1.76785C16.0372 1.4721 15.9198 1.18843 15.7108 0.979202C15.5017 0.769974 15.2182 0.652317 14.9225 0.6521H1.53691ZM1.53691 5.114C1.39039 5.11397 1.24529 5.1428 1.10991 5.19886C0.974527 5.25492 0.851518 5.3371 0.747908 5.44072C0.644297 5.54433 0.562116 5.66734 0.506059 5.80273C0.450002 5.93811 0.421168 6.08322 0.421204 6.22975C0.421421 6.5255 0.539073 6.80905 0.748294 7.01807C0.957514 7.2271 1.24118 7.34448 1.53691 7.3444H14.9225C15.218 7.34419 15.5014 7.22668 15.7104 7.01769C15.9194 6.8087 16.0369 6.52531 16.0371 6.22975C16.0372 5.934 15.9198 5.65033 15.7108 5.4411C15.5017 5.23188 15.2182 5.11422 14.9225 5.114H1.53691ZM1.53691 9.57591C1.24117 9.57583 0.957513 9.69321 0.748292 9.90224C0.539071 10.1113 0.42142 10.3948 0.421204 10.6906C0.421169 10.8371 0.450004 10.9822 0.506061 11.1176C0.562119 11.253 0.6443 11.376 0.74791 11.4796C0.85152 11.5832 0.974529 11.6654 1.10991 11.7214C1.24529 11.7775 1.39039 11.8063 1.53691 11.8063H14.9225C15.2182 11.8061 15.5017 11.6884 15.7108 11.4792C15.9198 11.27 16.0372 10.9863 16.0371 10.6906C16.0369 10.395 15.9194 10.1116 15.7104 9.90262C15.5014 9.69363 15.218 9.57612 14.9225 9.57591H1.53691Z" />
  </svg>,
  "HamburgerIcon"
);

export default HamburgerIcon;
