import React from "react";
import { createSvgIcon } from "@mui/material";

const ComingSoon = createSvgIcon(
  <svg
    width="87"
    height="39"
    viewBox="0 0 87 39"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M7.58 14.48C6.5 14.48 5.49333 14.3067 4.56 13.96C3.64 13.6 2.84 13.0933 2.16 12.44C1.48 11.7867 0.946667 11.02 0.56 10.14C0.186667 9.26 0 8.29333 0 7.24C0 6.18667 0.186667 5.22 0.56 4.34C0.946667 3.46 1.48 2.69333 2.16 2.04C2.85333 1.38667 3.66 0.886666 4.58 0.539999C5.5 0.18 6.50667 0 7.6 0C8.81333 0 9.90667 0.213333 10.88 0.64C11.8667 1.05333 12.6933 1.66667 13.36 2.48L11.28 4.4C10.8 3.85333 10.2667 3.44667 9.68 3.18C9.09333 2.9 8.45333 2.76 7.76 2.76C7.10667 2.76 6.50667 2.86667 5.96 3.08C5.41333 3.29333 4.94 3.6 4.54 4C4.14 4.4 3.82667 4.87333 3.6 5.42C3.38667 5.96667 3.28 6.57333 3.28 7.24C3.28 7.90667 3.38667 8.51333 3.6 9.06C3.82667 9.60667 4.14 10.08 4.54 10.48C4.94 10.88 5.41333 11.1867 5.96 11.4C6.50667 11.6133 7.10667 11.72 7.76 11.72C8.45333 11.72 9.09333 11.5867 9.68 11.32C10.2667 11.04 10.8 10.62 11.28 10.06L13.36 11.98C12.6933 12.7933 11.8667 13.4133 10.88 13.84C9.90667 14.2667 8.80667 14.48 7.58 14.48Z" />
    <path d="M22.055 14.48C20.9483 14.48 19.9217 14.3 18.975 13.94C18.0417 13.58 17.2283 13.0733 16.535 12.42C15.855 11.7667 15.3217 11 14.935 10.12C14.5617 9.24 14.375 8.28 14.375 7.24C14.375 6.2 14.5617 5.24 14.935 4.36C15.3217 3.48 15.8617 2.71333 16.555 2.06C17.2483 1.40667 18.0617 0.899999 18.995 0.539999C19.9283 0.18 20.9417 0 22.035 0C23.1417 0 24.155 0.18 25.075 0.539999C26.0083 0.899999 26.815 1.40667 27.495 2.06C28.1883 2.71333 28.7283 3.48 29.115 4.36C29.5017 5.22667 29.695 6.18667 29.695 7.24C29.695 8.28 29.5017 9.24667 29.115 10.14C28.7283 11.02 28.1883 11.7867 27.495 12.44C26.815 13.08 26.0083 13.58 25.075 13.94C24.155 14.3 23.1483 14.48 22.055 14.48ZM22.035 11.72C22.6617 11.72 23.235 11.6133 23.755 11.4C24.2883 11.1867 24.755 10.88 25.155 10.48C25.555 10.08 25.8617 9.60667 26.075 9.06C26.3017 8.51333 26.415 7.90667 26.415 7.24C26.415 6.57333 26.3017 5.96667 26.075 5.42C25.8617 4.87333 25.555 4.4 25.155 4C24.7683 3.6 24.3083 3.29333 23.775 3.08C23.2417 2.86667 22.6617 2.76 22.035 2.76C21.4083 2.76 20.8283 2.86667 20.295 3.08C19.775 3.29333 19.315 3.6 18.915 4C18.515 4.4 18.2017 4.87333 17.975 5.42C17.7617 5.96667 17.655 6.57333 17.655 7.24C17.655 7.89333 17.7617 8.5 17.975 9.06C18.2017 9.60667 18.5083 10.08 18.895 10.48C19.295 10.88 19.7617 11.1867 20.295 11.4C20.8283 11.6133 21.4083 11.72 22.035 11.72Z" />
    <path d="M32.13 14.24V0.239999H34.81L40.77 10.12H39.35L45.21 0.239999H47.87L47.91 14.24H44.87L44.85 4.9H45.41L40.73 12.76H39.27L34.49 4.9H35.17V14.24H32.13Z" />
    <path d="M51.2316 14.24V0.239999H54.4716V14.24H51.2316Z" />
    <path d="M57.7941 14.24V0.239999H60.4741L68.7341 10.32H67.4341V0.239999H70.6341V14.24H67.9741L59.6941 4.16H60.9941V14.24H57.7941Z" />
    <path d="M80.7064 14.48C79.5997 14.48 78.5797 14.3067 77.6464 13.96C76.7264 13.6 75.9197 13.0933 75.2264 12.44C74.5464 11.7867 74.0131 11.02 73.6264 10.14C73.2531 9.26 73.0664 8.29333 73.0664 7.24C73.0664 6.18667 73.2531 5.22 73.6264 4.34C74.0131 3.46 74.5531 2.69333 75.2464 2.04C75.9397 1.38667 76.7531 0.886666 77.6864 0.539999C78.6197 0.18 79.6464 0 80.7664 0C82.0064 0 83.1197 0.206666 84.1064 0.619999C85.1064 1.03333 85.9464 1.63333 86.6264 2.42L84.5464 4.34C84.0397 3.80667 83.4864 3.41333 82.8864 3.16C82.2864 2.89333 81.6331 2.76 80.9264 2.76C80.2464 2.76 79.6264 2.86667 79.0664 3.08C78.5064 3.29333 78.0197 3.6 77.6064 4C77.2064 4.4 76.8931 4.87333 76.6664 5.42C76.4531 5.96667 76.3464 6.57333 76.3464 7.24C76.3464 7.89333 76.4531 8.49333 76.6664 9.04C76.8931 9.58667 77.2064 10.0667 77.6064 10.48C78.0197 10.88 78.4997 11.1867 79.0464 11.4C79.6064 11.6133 80.2197 11.72 80.8864 11.72C81.5264 11.72 82.1464 11.62 82.7464 11.42C83.3597 11.2067 83.9531 10.8533 84.5264 10.36L86.3664 12.7C85.6064 13.2733 84.7197 13.7133 83.7064 14.02C82.7064 14.3267 81.7064 14.48 80.7064 14.48ZM83.4064 12.28V7.02H86.3664V12.7L83.4064 12.28Z" />
    <path d="M18.3999 38.48C17.2799 38.48 16.2066 38.3333 15.1799 38.04C14.1533 37.7333 13.3266 37.34 12.6999 36.86L13.7999 34.42C14.3999 34.8467 15.1066 35.2 15.9199 35.48C16.7466 35.7467 17.5799 35.88 18.4199 35.88C19.0599 35.88 19.5733 35.82 19.9599 35.7C20.3599 35.5667 20.6533 35.3867 20.8399 35.16C21.0266 34.9333 21.1199 34.6733 21.1199 34.38C21.1199 34.0067 20.9733 33.7133 20.6799 33.5C20.3866 33.2733 19.9999 33.0933 19.5199 32.96C19.0399 32.8133 18.5066 32.68 17.9199 32.56C17.3466 32.4267 16.7666 32.2667 16.1799 32.08C15.6066 31.8933 15.0799 31.6533 14.5999 31.36C14.1199 31.0667 13.7266 30.68 13.4199 30.2C13.1266 29.72 12.9799 29.1067 12.9799 28.36C12.9799 27.56 13.1933 26.8333 13.6199 26.18C14.0599 25.5133 14.7133 24.9867 15.5799 24.6C16.4599 24.2 17.5599 24 18.8799 24C19.7599 24 20.6266 24.1067 21.4799 24.32C22.3333 24.52 23.0866 24.8267 23.7399 25.24L22.7399 27.7C22.0866 27.3267 21.4333 27.0533 20.7799 26.88C20.1266 26.6933 19.4866 26.6 18.8599 26.6C18.2333 26.6 17.7199 26.6733 17.3199 26.82C16.9199 26.9667 16.6333 27.16 16.4599 27.4C16.2866 27.6267 16.1999 27.8933 16.1999 28.2C16.1999 28.56 16.3466 28.8533 16.6399 29.08C16.9333 29.2933 17.3199 29.4667 17.7999 29.6C18.2799 29.7333 18.8066 29.8667 19.3799 30C19.9666 30.1333 20.5466 30.2867 21.1199 30.46C21.7066 30.6333 22.2399 30.8667 22.7199 31.16C23.1999 31.4533 23.5866 31.84 23.8799 32.32C24.1866 32.8 24.3399 33.4067 24.3399 34.14C24.3399 34.9267 24.1199 35.6467 23.6799 36.3C23.2399 36.9533 22.5799 37.48 21.6999 37.88C20.8333 38.28 19.7333 38.48 18.3999 38.48Z" />
    <path d="M33.3538 38.48C32.2472 38.48 31.2205 38.3 30.2738 37.94C29.3405 37.58 28.5272 37.0733 27.8338 36.42C27.1538 35.7667 26.6205 35 26.2338 34.12C25.8605 33.24 25.6738 32.28 25.6738 31.24C25.6738 30.2 25.8605 29.24 26.2338 28.36C26.6205 27.48 27.1605 26.7133 27.8538 26.06C28.5472 25.4067 29.3605 24.9 30.2938 24.54C31.2272 24.18 32.2405 24 33.3338 24C34.4405 24 35.4538 24.18 36.3738 24.54C37.3072 24.9 38.1138 25.4067 38.7938 26.06C39.4872 26.7133 40.0272 27.48 40.4138 28.36C40.8005 29.2267 40.9938 30.1867 40.9938 31.24C40.9938 32.28 40.8005 33.2467 40.4138 34.14C40.0272 35.02 39.4872 35.7867 38.7938 36.44C38.1138 37.08 37.3072 37.58 36.3738 37.94C35.4538 38.3 34.4472 38.48 33.3538 38.48ZM33.3338 35.72C33.9605 35.72 34.5338 35.6133 35.0538 35.4C35.5872 35.1867 36.0538 34.88 36.4538 34.48C36.8538 34.08 37.1605 33.6067 37.3738 33.06C37.6005 32.5133 37.7138 31.9067 37.7138 31.24C37.7138 30.5733 37.6005 29.9667 37.3738 29.42C37.1605 28.8733 36.8538 28.4 36.4538 28C36.0672 27.6 35.6072 27.2933 35.0738 27.08C34.5405 26.8667 33.9605 26.76 33.3338 26.76C32.7072 26.76 32.1272 26.8667 31.5938 27.08C31.0738 27.2933 30.6138 27.6 30.2138 28C29.8138 28.4 29.5005 28.8733 29.2738 29.42C29.0605 29.9667 28.9538 30.5733 28.9538 31.24C28.9538 31.8933 29.0605 32.5 29.2738 33.06C29.5005 33.6067 29.8072 34.08 30.1938 34.48C30.5938 34.88 31.0605 35.1867 31.5938 35.4C32.1272 35.6133 32.7072 35.72 33.3338 35.72Z" />
    <path d="M50.2288 38.48C49.1222 38.48 48.0955 38.3 47.1488 37.94C46.2155 37.58 45.4022 37.0733 44.7088 36.42C44.0288 35.7667 43.4955 35 43.1088 34.12C42.7355 33.24 42.5488 32.28 42.5488 31.24C42.5488 30.2 42.7355 29.24 43.1088 28.36C43.4955 27.48 44.0355 26.7133 44.7288 26.06C45.4222 25.4067 46.2355 24.9 47.1688 24.54C48.1022 24.18 49.1155 24 50.2088 24C51.3155 24 52.3288 24.18 53.2488 24.54C54.1822 24.9 54.9888 25.4067 55.6688 26.06C56.3622 26.7133 56.9022 27.48 57.2888 28.36C57.6755 29.2267 57.8688 30.1867 57.8688 31.24C57.8688 32.28 57.6755 33.2467 57.2888 34.14C56.9022 35.02 56.3622 35.7867 55.6688 36.44C54.9888 37.08 54.1822 37.58 53.2488 37.94C52.3288 38.3 51.3222 38.48 50.2288 38.48ZM50.2088 35.72C50.8355 35.72 51.4088 35.6133 51.9288 35.4C52.4622 35.1867 52.9288 34.88 53.3288 34.48C53.7288 34.08 54.0355 33.6067 54.2488 33.06C54.4755 32.5133 54.5888 31.9067 54.5888 31.24C54.5888 30.5733 54.4755 29.9667 54.2488 29.42C54.0355 28.8733 53.7288 28.4 53.3288 28C52.9422 27.6 52.4822 27.2933 51.9488 27.08C51.4155 26.8667 50.8355 26.76 50.2088 26.76C49.5822 26.76 49.0022 26.8667 48.4688 27.08C47.9488 27.2933 47.4888 27.6 47.0888 28C46.6888 28.4 46.3755 28.8733 46.1488 29.42C45.9355 29.9667 45.8288 30.5733 45.8288 31.24C45.8288 31.8933 45.9355 32.5 46.1488 33.06C46.3755 33.6067 46.6822 34.08 47.0688 34.48C47.4688 34.88 47.9355 35.1867 48.4688 35.4C49.0022 35.6133 49.5822 35.72 50.2088 35.72Z" />
    <path d="M60.3038 38.24V24.24H62.9838L71.2438 34.32H69.9438V24.24H73.1438V38.24H70.4838L62.2038 28.16H63.5038V38.24H60.3038Z" />
  </svg>,
  "ComingSoon"
);

export default ComingSoon;
